.App {
  text-align: center;
}
body {
    font-family: Arial,sans-serif;
    font-family: var(--arc-font-body);
    font-size: .875rem;
    font-size: var(--arc-font-size-small);
    font-weight: 400;
    font-weight: var(--arc-font-weight-normal);
    letter-spacing: normal;
    letter-spacing: var(--arc-letter-spacing-normal);
    line-height: 1.8;
    line-height: var(--arc-line-height-normal);
    margin: 0
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .zdog-canvas {
  background: #a6a6a6;
  cursor: move;
}
 */
/* .font-face-gm {
  font-family: "TNR";
 } */
 .job-actions{
  display: flex;
  justify-content: space-around;
 }
 .job-actions button{
  border: none;
  font-size:22px;
 }
 .card-body h6{
  display: flex;
  gap:24px
 }
 .table-loading{
  display: flex;
  justify-content: center;
 }

 .fixed_header{
  height: 500px;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody{
  /* display:block; */
  /* width: 100%; */
  overflow: auto;
  /* height: 300px; */
}

.fixed_header thead tr {
  /* display: block; */
}

.fixed_header thead {
  background: #6c757d;
  color:#fff;
}

.fixed_header th, .fixed_header td {
  padding: 5px;
  text-align: left;
  min-width: 200px;
  font-size:22px;
}

.home_landing {
  overflow: scroll;
  /* min-height: 20vh; */
  height: 90vh;
  width: 100%;
  /* align-items: left; */
  /* padding-top: 20px; */
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 70px;
}

.viewpage_landing {
  overflow: scroll;
  height: 100vh;
  width: 100%;
  align-items: left;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 70px;
}

.unauth_landing{
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.4);
}

.text_center{
  margin-top: 50px;
  text-align: center;
}
.red_text {
color: #c00000;
}

.termination_btn{
  padding-right: 25px;
}

.viewjob_overflow {
  overflow: scroll;
  height: 800px;
}

.viewjob_cell {
  min-width: 150px;
}

.headerCells {
  background-color: #87aee8;
}
.nestedHeaderCells {
  background-color: #adb5bd;
}
/* .react-bootstrap-table .headerCells th.sortable{
  max-width: 75px;
} */
.react-bootstrap-table .headerCells .expand-cell-header{
  width: 70px;
}

.fontSize {
  font-size:22px;
}

.font20Size {
  font-size:20px;
}

.fontSize h5 span {
  padding-left: 20px;
}

.tcluster {
  padding: 15px;
}

.btnlink {
  background: none;
  border: none;
  text-decoration: underline;
}

.btnlink:hover {
  color: #007bff;
}

.paddingStyle {
  padding-bottom: 40px;
}

.folder-structure ul {
  list-style-type: none;
  padding-left: 16px;
}

.folder-structure li {
  margin-bottom: 4px;
}

.folder-structure span {
  display: flex;
  align-items: center;
}

.folder-structure svg {
  margin-right: 4px;
}


.overflowStyle  {
  overflow: scroll;
  height: 2800px;
}

.apinnerStyle {
  font-size: 3rem;
  --stroke-color: "#28a745";
}

.expansionTextStyle  {
  padding: 30px;
  font-style: oblique
}
